<!-- Modal to control deletion of a batch. Posted batches cannot be deleted -->
<template>
  <v-dialog justify="center" :value="value" persistent max-width="400">
    <v-card color=#fafafa>
      <section class="ma-0 pa-6" v-if='selectedBatch.status !== "POSTED"'>
        <v-row justify="center">
          <v-icon x-large color="red">mdi-trash-can-outline</v-icon>
        </v-row>
        <v-row justify="center">
            <span class='pa-4'>
            Delete "{{selectedBatch.name}}"?
            </span>
            <span class='pa-4'>
            Last Updated: {{formatDateTime(selectedBatch.last_update_date)}}
            </span>
            <span class='pa-4'>
            Warning: This action cannot be undone.
            </span>
        </v-row>
        <v-row justify="center" class="pa-4">
          <v-btn height="40" class="elevation-1" @click.stop="$emit('update:value', false)">
            No
          </v-btn>
          <v-btn color="#0c67a5" height="40" class="white--text ml-2"
            @click="deleteBatch(selectedBatch.id)">Yes
          </v-btn>
        </v-row>
      </section>
      <section class="ma-0 pa-6" v-else-if='selectedBatch.status === "POSTED"'>
        <v-row justify="center">
          <v-icon x-large color="red">mdi-cancel</v-icon>
        </v-row>
        <v-row justify="center">
            <span class='pa-4'>
            Posted Batches may not be deleted.
            </span>
            <span class='pa-4'>
            For more info contact iPro Systems Support.
            </span>
        </v-row>
        <v-row justify="center" class="pa-4">
          <v-btn height="40" class="elevation-1" @click.stop="$emit('update:value', false)">
            OK
          </v-btn>
        </v-row>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import BillingBatch from '@/axios/billing-batch'
import { dateFormat } from '@/mixins/date-format'
export default {
  name: 'DeleteBatch',

  props: [
    'value',
    'selectedBatch'
  ],

  mixins: [
    dateFormat,
  ],
  methods: {
    async deleteBatch (id) {
        this.$store.dispatch('setSnackbar', { status: 'alert', text: `Deleting ${this.selectedBatch.name}, Please Wait` })
        await BillingBatch.delete(id).then(() => {
        this.$emit('update:value', false)
          this.$store.dispatch('setSnackbar', { status: 'success', text: `Deleted ${this.selectedBatch.name} Successfully` })
        }).catch (
          this.$store.dispatch('setSnackbar', { status: 'alert', text: `Deleting ${this.selectedBatch.name}, Please Wait` })
        )
      }
      

    }
  
}
</script>